<template>
  <div>
    <div class="page-heading" style="margin-bottom: 0px;">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-4">
          <div class="heading-content">
            <div class="heading-title">
              <h2>Objetivos</h2>
              <p class="hidden-sm">
                Crie objetivos financeiros para ajudar alcançar as suas metas  🤑
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-8">
          <div class="slide-tab-header">
            <slide-tab tabPositionMax="50" firstName="Abertos" lastName="Concluídos" firstTabColor="last-tab-color" lastTabColor="first-tab-color" @changeTabCallBack="changeTab"/>
          </div>
        </div>
      </div>
      <hr>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <div class="card">
          <div class="card-header">
            <h4>Total salvo</h4>
          </div>
          <div class="card-body" style="padding-top: 0;">
            <section class="text-center">
              <h1 class="reports-expenses text-left font-w-600 ng-binding" style="margin-top: 0px;">{{goals.report.goals.totalSpent | currency }}</h1>
              <hr>
              <div class="chart-insights  text-left">
                <p>Meta total</p>
                <h4 style="margin: 5px;">
                {{goals.report.goals.total | currency}}</h4>
              </div>
            </section>
            <div></div>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
        <div class="card">
          <div class="card-body" style="padding-top: 5px;">
            <section class="text-center">
              <svg
                data-v-31a768e4=""
                width="170"
                height="170"
                xmlns="http://www.w3.org/2000/svg"
                class="circle-chart"
                viewBox="-3 0 40 30"
              >
                <circle
                  class="circle-chart__background"
                  stroke="#efefef"
                  stroke-width="2"
                  fill="none"
                  cx="16.91549431"
                  cy="16.91549431"
                  r="15.91549431"
                />
                <circle
                  class="circle-chart__circle"
                  stroke="#18b272"
                  stroke-width="2"
                  :stroke-dasharray="`${goals.report.goals.spentPercent},100`"
                  stroke-linecap="round"
                  fill="none"
                  cx="16.91549431"
                  cy="16.91549431"
                  r="15.91549431"
                />
                <g class="circle-chart__info">
                  <text
                    class="circle-chart__percent font-w-100"
                    x="16.91549431"
                    y="20"
                    alignment-baseline="central"
                    text-anchor="middle"
                    font-size="7"
                  >
                    {{goals.report.goals.spentPercent}}%
                  </text>
                </g>
              </svg>
            </section>
            <div style="padding-top:2px;"></div>
          </div>
        </div>
      </div>
      
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="tab-content relative">
          <div class="tab-pane printable" :class="{'show': tabActive == 'tab-all'}" v-if="tabActive == 'tab-all'">
            <div class="card table-responsive longer" style="border-radius: 10px !important; margin-bottom: 0px;">
              <h3 class="display-4">Objetivos</h3>
              <button class="btn btn-info pull-right action-add" type="button" @click="createBudget('create-goal-modal')"><span><i class="mdi mdi-plus-circle-outline"></i></span>  Novo objetivo</button>
              <hr>
              <div class="row ng-scope" v-if="goals.goals.length == 0">
                <img :src="`assets/images/goal_man.png`" class="nothing-image w-15p" style="width: 10% !important">
                <h3 class="nothing-text">Nenhum objetivo encontrado</h3>
              </div>
              <ul class="list-group list-budget" v-if="goals.goals">
                <li class="list-group-item ng-scope no-border" v-for="(item, i) in goals.goals" :key="i" @click="budgetDetail('goal-detail-modal', item)">
                  <div class="row" style="margin-bottom: 0px;">
                    <div class="col-sm-2 col-md-2 col-lg-1 col-xs-12">
                      <span class="chart-legend-item relative" style="border:none; margin:0px; padding:0px; margin-top:15px;">
                        <div class="icon-category-overview" :style="`margin-right:0px;background: ${item.category.color}; width: 60px;height: 60px; padding-top:15px;`">
                          <img :src="`/assets/images/icons/png/${item.category.icon}.png`" />
                        </div>
                      </span>
                      <hr class="hr-budget">
                    </div>
                    <div class="col-md-10 col-sm-10 col-lg-11 col-xs-12" style="padding-bottom: 10px;">
                      <span class="text-success"><span class="ng-binding"><strong>{{item.category.name}}</strong></span></span>
                      <span class="ng-binding transaction-note hide"><br></span>
                      <div class="transaction-progress">
                        <div class="item" style="margin:0px; width: 100%; margin-top: 12px;">
                          <strong class="pull-right ng-binding"> {{item.progress}}%</strong>
                          <p class="text-muted">{{item.spent | currency }} / {{item.value | currency }}</p>
                          <div class="progress progress-bar-success-alt">
                            <div class="progress-bar progress-bar-success" role="progressbar" :class="{'progress-bar-danger': item.progress > 85, 'progress-bar-warning': item.progress > 70 && item.progress < 85, 'progress-bar-success': cards.progress < 70 }" :style="`width:${item.progress}%`"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <br>
    <create-goal-modal id="create-goal-modal" @saveGoal="request"/>
    <edit-goal-modal id="edit-goal-modal" @saveGoal="request"/>
    <goal-detail-modal id="goal-detail-modal" @updateGoal="request"/>
  </div>
</template>

<script>
// @ is an alias to /src
// @ is an alias to /src
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { LineChart } from "echarts/charts";
import { GaugeChart } from "echarts/charts";
import CreateGoalModal from '@/components/CreateGoalModal.vue';
import EditGoalModal from '@/components/EditGoalModal.vue';
import GoalDetailModal from '@/components/GoalDetailModal.vue';
import SlideTab from '@/components/SlideTab';

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
} from "echarts/components";
import AppService from '@/services/app.service';
import pace from 'pace-js';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  LineChart,
  GaugeChart,
]);

export default {
  name: 'Home',
  components: {
    GoalDetailModal,
    SlideTab,
    CreateGoalModal,
    EditGoalModal,
  },
  computed: {
    classes() {
      return ['wizard'];
    },
    info() {
      return JSON.parse(localStorage.getItem('stimper_user'));
    },
  },
  data() {
    return {
      status: 'open',
      miniTab: {
        tab: 0,
        indicator: 0,
      },
      monthStart: 0,
      cards: [],
      monthName: '',
      categories: [],
      wallets: [],
      filters: [
        {
          field: '',
          values: [
          ],
        },
      ],
      fields: [
        { label: 'Contas', value: 'account_id' },
        { label: 'Categoria', value: 'category' },
      ],
      filterAll: [
        { label: 'Cartão', value: 'card' },
        { label: 'Conta', value: 'account' },
      ],
      optionsDate: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 360,
        },
        locale: {
          format: 'DD/MM/YYYY',
          cancelLabel: 'Cancelar',
          // applyLabel: 'Filtrar',
          applyLabel: 'Ok',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      tabActive: 'tab-all',
      hourType: 'sun',
      hourTypeText: 'Bom dia',
      totalAccount: 0,
      from: '',
      to: '',
      no_card: true,
      month: '',
      report: {},
      goals: {goals:[], report: {goals: {}}},
      isLoading: true,
      monthsList: [
        { id: '01', name: 'Janeiro', index: 0},
        { id: '02', name: 'Fevereiro', index: 1},
        { id: '03', name: 'Março', index: 2},
        { id: '04', name: 'Abril', index: 3},
        { id: '05', name: 'Maio', index: 4},
        { id: '06', name: 'Junho', index: 5},
        { id: '07', name: 'Julho', index: 6},
        { id: '08', name: 'Agosto', index: 7},
        { id: '09', name: 'Setembro', index: 8},
        { id: '10', name: 'Outubro', index: 9},
        { id: '11', name: 'Novembro', index: 10},
        { id: '12', name: 'Dezembro', index: 11},
      ],
      months: [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
      ],
    };
  },
  provide: {
  },
  mounted () {
    window.scrollTo(0, 0);
    this.monthStart = new Date().getMonth();
  },
  created() {
    moment.locale('pt_BR');
    this.monthStart = new Date().getMonth();
    this.month      = new Date().getMonth();
    this.monthName  = moment(new Date()).format('MMMM');

    this.from = `${moment(new Date()).format('YYYY-MM')}-01`;
    this.to = moment(this.from, "YYYY-MM-DD").endOf('month');
    this.request();

    this.$root.$on('register.transaction', this.request);
    this.$root.$on('call_remove_modal', this.removeGoal);
    this.$root.$on('call_edit_modal', this.editGoal);
    this.request();
  },
  methods: {
    changeTab(tab) {
      this.miniTab.tab = tab;
      switch (tab) {
        case 0:
          this.status = 'open';
          break;
        case 1:
          this.status = 'closed';
          break;
        default:
          break;
      }
      this.request();
    },
    navigateDateTo (month) {
      this.from = `${moment(new Date()).format('YYYY')}-${month.id}-01`;
      this.to = moment(this.from, "YYYY-MM-DD").endOf('month');
      this.month = month.id;
      this.monthStart = month.index;
      this.monthName  = month.name; 
      this.request();
    },
    deleteGoal(id) {
      AppService.deleteGoal(id).then(
        (response) => {
          this.isSending = false;
          console.log(response);
          this.$toast.show({
            title: "Sucesso",
            content: "Orçamento removido!",
            type: "success",
          });
          this.request();
        },
        (error) => {
          console.log(error);
          this.content = error;
          this.isSending = false;
          this.$toast.show({
            title: "Erro",
            content: "Erro interno, por favor tente novamente mais tarde",
            type: "error",
          });
        }
      );
    },
    removeGoal(id) {
      Swal.fire({
        title: `Remover objetivo?`,
        text: 'Tem certeza que deseja remover esse objetivo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.deleteGoal(id);
        }
      });
    },
    createTransaction(id, type, isCard) {
      const data = {
        isCard: isCard,
        type: type,
        card: null,
        bill_type: {
          type: 'M',
        },
        is_recurrence: false,
        invoice_pay: false,
        invoice: null,
        account: null,
        check_sub: true,
      };
      this.$root.$emit('show.modal', id, data);
    },
    createBudget(id) {
      const data = {
        month: this.month,
        date: this.from,
        monthStart: this.monthStart,
        monthName: this.monthName,
        value: 0,
      };
      this.$root.$emit('show.modal', id, data);
    },
    editGoal(item) {
      item.dead_line = moment(item.dead_line).format('DD/MM/YYYY'),
      this.$root.$emit('show.modal', 'edit-goal-modal', item);
    },
    budgetDetail(id, item) {
      this.$root.$emit('show.modal', id, item);
    },
    changeDate(date) {
      console.log(date);
      if (date) {
        this.from = moment(date[0], 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        );
        this.to = moment(date[1], 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        );
        this.request();
      }
    },
    request() {
      pace.start();
      AppService.getGoals({
        status: this.status,
      }).then(
        (response) => {
          if (response.goals) {
            this.goals = response.goals;
          }
          if (response.status == 301) {
            this.$router.push({ name: 'Premium', params: { expire: true }});
          }
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );
    },
    addFilter() {
      this.filters.push({
        field: '',
        values: null,
      });
    },
    removeFilter(n) {
      this.filters.splice(n, 1);
    },
    print () {
      window.print();
    },
    filterRequest() {
      this.request();
    },
  },
};
</script>

<style scoped lang="scss">
.cashflow-print {
  position: absolute;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.page-heading {
  margin-bottom: 0px;
}
.chart {
  height: 250px;
  top: -5px;
  left: 0;
  position: absolute;
}
.chart-line {
  height: 350px;
  margin-top: 0px;
}
.swiper-slide{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.swiper-container {
  height : 500px;
}
.figure-stats {
  background-color: #F9F9F9;
  border: 1.2px solid #E7EAEE;
  margin-top: 0px;
}
.card-header {
  border-bottom: none;
}
.figure-section {
  padding-top: 10px;
  padding-bottom: 10px;
}
.heading-title {
  padding-left: 0px;
}
.total-amount {
  margin-top: 10px;
  display: block;
}
.btn-remove-filter {
  height: 35px !important;
  border-radius: 50%;
  width: 35px;
  padding: 0px !important;
  text-align: center;
}
.btn-remove-filter i {
  margin-right: 0;
}
.list-budget .transaction-progress .progress {
  height: 8px;
}
.btn-edit-category {
  width: 40%;
}
.btn-remove-category {
  width: 40%;
}
.budget-buttons {
  position: absolute;
  top: 0;
  width: 120px;
  right: 0;
}
@media only screen and (max-width: 570px){
  .budget-buttons {
    top: -40px !important;
  }
}
.include-card-expense {
  position: absolute;
  left: 20px;
  z-index: 1;
}
.list-group-item {
  padding-bottom: 25px !important;
  cursor: pointer;
}
.list-group-item:hover {
  background: #f9f9f9 !important;
}
.action-add {
  position: absolute;
  right: 20px;
  top: 20px;
}
</style>
